/**
 * filename: sdu.js
 *
 * This is the main script for the SDU website
 */

import '@fontsource-variable/noto-sans-hk';
import '@fontsource-variable/noto-serif-hk';
import '@fontsource-variable/open-sans';
import '../css/shared.css';
import '../scss/sdu.scss';
import * as bootstrap from 'bootstrap'

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
